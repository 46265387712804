.support-ticket {
	height: 100%;
	> div {
		height: 100%;
	}
	.Polaris-Tabs__Panel {
		height: calc(100% - 55px);
	}
	.content-wrapper {
		padding: 0;
		height: 100%;
	}
	.ticket {
		padding: 1rem;
	}
}
.ticket {
	.Polaris-Tabs__Wrapper {
		background-color: transparent;
		.Polaris-Tabs {
			background-color: transparent;
		}
	}
	.Polaris-TextField.Polaris-TextField--multiline {
		background-color: transparent;
		border-radius: 0px 0px 10px 10px;
		.Polaris-TextField__Backdrop {
			display: none;
		}
		textarea {
			outline: none !important;
			background-color: transparent !important;
		}
	}
	.Polaris-Tabs__Tab {
		border-radius: 10px 10px 0px 0px;
		.Polaris-Tabs__Title {
			border-bottom: 1px solid $main1 !important;
			> div {
				margin-top: -15px;
			}
			.Polaris-Icon {
				display: inline-block;
				position: relative;
				top: 10px;
				margin-right: 10px;
				border-radius: 20px;
				height: 30px;
				width: 30px;
				padding: 8px;
				svg {
					width: 15px;
					height: 15px;
				}
			}
		}
	}

	button#internal {
		// color: #fff;

		// .Polaris-Icon {
		// 	background-color: rgba($yellow, 0.5);
		// 	fill: $yellow;
		// }
		// &.Polaris-Tabs__Tab--selected,
		// &.Polaris-Tabs__Tab--active {
		// 	background-color: rgba($yellow, 0.3);
		// }
	}

	button#visible {
		// .Polaris-Icon {
		// 	background-color: var(--main2);
		// }
		// &.Polaris-Tabs__Tab--selected,
		// &.Polaris-Tabs__Tab--active {
		// 	background-color: var(--modals-input-bg);
		// }
	}

	.textfield-wrapper {
		border-radius: 0px 0px 10px 10px;
		min-height: 60px;
	}
	div#internal {
		// color: #fff;

		textarea {
			color: $yellow;
		}
		.textfield-wrapper {
			background-color: rgba($yellow, 0.3);
		}
		.ticket-action {
			padding: 5px;
			color: rgba($yellow, 0.5);
			text-decoration: underline;
		}
	}
	div#visible {
		// color: #fff;

		textarea {
			color: var(--textColor);
		}
		.textfield-wrapper {
			background-color: var(--modals-input-bg);
		}
		.ticket-action {
			padding: 5px;
			color: var(--textColor2);
			text-decoration: underline;
		}
	}
}
.ticketfield-header {
	padding: 0.625rem;
	position: relative;

	.ticketfield-showCC_btn {
		position: absolute;
		right: 10px;
		top: 10px;
	}

	.ticketfield_ccs-wrapper {
		display: flex;
		gap: 0.1875rem;
		flex-direction: column;
	}

	.textfield-header_toHeader {
		display: flex;
		align-items: center;
	}
}
.internal-conversation {
	margin-top: 10px;
	padding-bottom: 15px;
	background-color: rgba($lightblue, 0.3);
	border-radius: 10px 10px 10px 10px;
	.icon-wrapper {
		padding-left: 10px;
		.Polaris-Icon {
			background-color: rgba($yellow, 0.5);
			fill: $yellow;
			display: inline-block;
			position: relative;
			top: 10px;
			margin-right: 10px;
			border-radius: 20px;
			height: 30px;
			width: 30px;
			padding: 8px;
			svg {
				width: 15px;
				height: 15px;
			}
		}
	}
}
.internal-comment {
	margin-top: 10px;
	padding-bottom: 15px;
	background-color: rgba($yellow, 0.3);
	border-radius: 10px 10px 10px 10px;
	.icon-wrapper {
		padding-left: 10px;
		.Polaris-Icon {
			background-color: rgba($yellow, 0.5);
			fill: $yellow;
			display: inline-block;
			position: relative;
			top: 10px;
			margin-right: 10px;
			border-radius: 20px;
			height: 30px;
			width: 30px;
			padding: 8px;
			svg {
				width: 15px;
				height: 15px;
			}
		}
	}
}
.ticket-header {
	padding: 1rem;
	display: flex;
	gap: 1.25rem;
	align-items: end;

	.Polaris-Button {
		background: var(--modals-input-bg) !important;
		height: max-content;
	}

	& > * {
		max-width: 33%;
		width: 33%;

		button {
			border-radius: 0.4688rem;
			width: 100%;

			.Polaris-Button__Text {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
		& > label {
			margin-bottom: 0.25rem;
			font-weight: 400;
			line-height: 1.25rem;
			font-size: 0.875rem;
		}
	}

	.user_followers_select_btn,
	.user_select_btn,
	.resource_picker_btn {
		button.Polaris-Button[type="button"]:not(.Polaris-Button--primary) {
			background-color: var(--modals-input-bg);
		}
	}

	.resource_picker_btn .Polaris-Button__Content .Polaris-Stack__Item > div {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

.ticketfield_ccs__contact {
	display: flex;
	align-items: center;
	gap: 0.3125rem;

	button.Polaris-Button.Polaris-Button--destructive {
		min-height: 0.9375rem;
		min-width: 0.9375rem;
		width: 0.9375rem;
		height: 0.9375rem;

		.Polaris-Icon {
			width: 0.9375rem;
			height: 0.9375rem;
		}
	}
}
